<template>
<OModal ref="modal" @show="() => isShown = true" @hidden="() => isShown = false">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{layoutName}}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="overflow-auto" style="max-height: 80vh;">
                    <JSONEditor v-model="layoutJSON" readonly/>
                </div>
            </div>
        </div>
    </div>
</OModal>
</template>

<script setup lang="ts">
import type { LayoutObject } from 'o365.modules.DataObject.Layout.ts';
import OModal from 'o365.vue.components.Modal.vue';
import JSONEditor from 'o365.vue.components.JSONEditor.vue';
import { ref, computed } from 'vue';

const props = defineProps<{
    layoutObject: LayoutObject,
    editable?: boolean
}>();

const modal = ref<OModal|null>(null);

const isShown = ref(false);

const layoutName = computed(() => {
    if (props.layoutObject.name) {
        return props.layoutObject.name;
    } else {
        return 'LAYOUT_PLACEHOLDER';
    }
});

const layoutJSON = computed(() => {
    return props.layoutObject?.layoutJSON;
});

const layoutModules = computed(() => {
    if (!isShown.value) {
        return [];
    } else {
        return props.layoutObject.modulesArray.map(layoutModule => ({
            value: layoutModule.getValue(),
            displayName: layoutModule.getDisplayName(),
            clear: () => layoutModule.reset(),
        }));
    }
});

function open() {
    modal.value?.show();
}

defineExpose({open});
</script>